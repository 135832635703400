<template>
<v-container fluid>
  <v-row class="justify-center">
    <v-col>
      <v-card elevation="0" min-width="400" height="400">
        <div class="d-flex">
          <div style="width:80%;">
            <BarChart :data="points1" :title="title1" :palette="['green','SlateGrey']"/>
          </div>
          <v-spacer></v-spacer>
          <div>
            <div class="chart-legend">
              <div @click="legend1(i)" v-for="(btn, i) in points1" class="chart-legend-item">
                <div class="justify-start">{{ btn.x }}</div>
                <div>{{ btn.y }}个</div>
              </div>
              <div @click="notParent" class="text-caption" style="top:300px;position:absolute;cursor:pointer;">有<span class="orange--text mx-1">{{notParentUsers.length}}</span>个员工未对齐OKR</div>
            </div>
          </div>
        </div>                       
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="0" min-width="500" height="400">
        <div class="d-flex">
          <div style="width:80%;">
            <BarChart :data="points2" :title="title2" :palette="['green','SlateGrey']"/>
          </div>
          <v-spacer></v-spacer>
          <div>
            <div class="chart-legend">
              <div @click="legend2(i)" v-for="(btn, i) in points2" class="chart-legend-item">
                <div class="justify-start">{{ btn.x }}</div>
                <div>{{ btn.y }}个</div>
              </div>
              <div @click="notDissolve" class="text-caption" style="top:300px;position:absolute;cursor:pointer;">有<span class="orange--text mx-1">{{notDissolveUsers.length}}</span>个员工未分解KR</div>
            </div>            
          </div>
        </div>  
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: 'RateBar',
  components: {
    BarChart: () => import('@/components/okrdashboard/BarChart.vue'),    
  },
  props: ['data1', 'data2', 'notParentUsers', 'notDissolveUsers'],
  computed: {
    points1: function () {
      return [{
          x: '已对齐',
          y: this.data1[0]
        },
        {
          x: '未对齐',
          y: this.data1[1]
        },
      ]
    },
    points2: function () {
      return [{
          x: '已分解',
          y: this.data2[0]
        },
        {
          x: '未分解',
          y: this.data2[1]
        },
      ]
    }
  },
  data() {
    return {
      title1: 'OKR对齐量',
      title2: 'KR分解（E-执行）'
    }
  },
  methods: {
    legend1(i) {
      this.$emit('legend1', i);
    },
    legend2(i) {
      this.$emit('legend2', i);
    },
    notDissolve() {
      this.$emit('notDissolve');
    },
    notParent() {
      this.$emit('notParent');
    }
  }
}
</script>
<style>

</style>